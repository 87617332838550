import { Box } from '@chakra-ui/react'

interface Props {
  width: string
}

const Icon: React.FC<Props> = ({ width }: Props) => (
  <Box w={width}>
    <svg viewBox="0 0 109 202" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect
        x="46.3024"
        y="124.198"
        width="16.4495"
        height="77.5479"
        fill="#93642D"
      />
      <rect
        x="46.3463"
        y="95.5269"
        width="16.4495"
        height="77.5479"
        fill="#714D22"
      />
      <path
        d="M54.0485 81.8996L100.856 162.972H7.24113L54.0485 81.8996Z"
        fill="#66A865"
      />
      <path
        d="M54.092 51.3505L93.7766 120.086H14.4075L54.092 51.3505Z"
        fill="#83C082"
      />
      <path
        d="M69.3463 77.5269H38.8463L34.3463 85.0269H73.8463L69.3463 77.5269Z"
        fill="#458244"
      />
      <path
        d="M76.1174 120.027H32.0397L27.8463 127.027H80.3463L76.1174 120.027Z"
        fill="#458244"
      />
      <path
        d="M54.6143 23.1512L86.1584 77.7873H23.0701L54.6143 23.1512Z"
        fill="#66A865"
      />
      <path d="M54.6143 0L80.4299 44.7141H28.7986L54.6143 0Z" fill="#83C082" />
      <path
        d="M66.8463 44.5269H42.3463L37.8463 52.0269H71.3463L66.8463 44.5269Z"
        fill="#458244"
      />
    </svg>
  </Box>
)

export default Icon
