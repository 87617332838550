import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { Center, Flex } from '@chakra-ui/react'
import Card from '../../components/Card'
import Layout from '../../components/Layout'
import LoginForm from './LoginForm'
import { useAuth } from '../../hooks/useAuth'
import { LoginDTO } from '../../types/user'

const LoginPage: React.FC = () => {
  const { t } = useTranslation()
  const { user, realmLogIn } = useAuth()
  const navigate = useNavigate()

  useEffect(() => {
    if (user) {
      navigate('/')
    }
  }, [user])

  const handleSubmit = async (values: LoginDTO) => {
    try {
      await realmLogIn(values)
    } catch (e) {
      // eslint-disable-next-line
      console.log(e)
    }
  }

  return (
    <Layout
      display="flex"
      contentBg="bg"
      minH={['80vh']}
      hideLogo
      alignItems="center"
      background={['#fffff', 'bg']}
    >
      <Center>
        <Flex direction={'column'}>
          <Card title={t('logInPage.login')}>
            <LoginForm onSubmit={handleSubmit} />
          </Card>
        </Flex>
      </Center>
    </Layout>
  )
}

export default LoginPage
