import { Flex } from '@chakra-ui/layout'
import { Link } from 'react-router-dom'
import { useAuth } from '../hooks/useAuth'
import { defaultPadding } from '../theme'
import Icon from './Basic/Icon'
import AvatarMenu from './Menu'

const TopBar: React.FC<{
  bgBehind?: boolean
  hideLogo?: boolean
  LeftComponent?: any
}> = ({ bgBehind, hideLogo, LeftComponent }) => {
  const { fullName, isAuthenticated } = useAuth()

  return (
    <Flex
      bg={[bgBehind ? 'rgba(255,255,255, 0.50)' : '#fff', '#fff']}
      top={0}
      zIndex={999}
      position="sticky"
      pointerEvents="all"
      borderRadius="0 0 10px 10px"
      h="4em"
      alignItems="center"
      justifyContent={isAuthenticated ? 'space-between' : 'center'}
      p={defaultPadding}
    >
      {LeftComponent ? (
        <LeftComponent />
      ) : (
        isAuthenticated && <AvatarMenu fullName={fullName!} />
      )}
      {!hideLogo && (
        <Link to="/">
          <Icon width={'2em'} />
        </Link>
      )}
    </Flex>
  )
}

export default TopBar
