import { Box, Input, InputGroup, InputProps } from '@chakra-ui/react'
import { useField } from 'formik'
import React, { FC, ForwardedRef } from 'react'
import { BaseProps, FormControl } from '../form-control'

export type InputControlProps = BaseProps & { inputProps?: InputProps } & {
  type?: 'password' | 'text' | 'email'
  otherValues?: any
  index?: number
}

export const FormikInput: FC<InputControlProps> = React.forwardRef(
  (props: InputControlProps, ref: ForwardedRef<HTMLInputElement>) => {
    const { name, children, placeholder, label, type, inputProps, ...rest } =
      props
    const [field] = useField(name)
    return (
      <FormControl marginY={4} name={name} label={label} {...rest}>
        <InputGroup>
          <Input
            {...field}
            value={field.value || ''}
            placeholder={placeholder}
            type={type || 'text'}
            id={name}
            {...inputProps}
            ref={ref}
          />
          <Box zIndex={0}>{children}</Box>
        </InputGroup>
      </FormControl>
    )
  },
)

export default FormikInput
