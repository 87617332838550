import { Avatar, Menu, MenuList, MenuButton, MenuItem } from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'
import { useAuth } from '../hooks'

interface Props {
  fullName: string
}

const AvatarMenu: React.FC<Props> = ({ fullName }: Props) => {
  const { signOut } = useAuth()
  const { t } = useTranslation()

  return (
    <Menu>
      <MenuButton>
        <Avatar name={fullName} size="sm" />
      </MenuButton>
      <MenuList>
        <MenuItem onClick={signOut}>{t('logInPage.signOut')}</MenuItem>
      </MenuList>
    </Menu>
  )
}

export default AvatarMenu
