import * as Realm from 'realm-web'
import { Suspense } from 'react'
import {
  ApolloClient,
  ApolloProvider,
  HttpLink,
  InMemoryCache,
} from '@apollo/client'
import { ChakraProvider } from '@chakra-ui/react'
import { library } from '@fortawesome/fontawesome-svg-core'
import {
  faPlus,
  faChevronLeft,
  faCopy,
  faDoorOpen,
} from '@fortawesome/free-solid-svg-icons'
import { Toaster } from 'react-hot-toast'
import Loader from './components/Loader'
import { AuthProvider } from './lib/auth'
import Routes from './Routes'
import theme from './theme'

// @ts-ignore
library.add(faPlus, faChevronLeft, faCopy, faDoorOpen)
// eslint-disable-next-line
const APP_ID = 'testi-hlgto'

const realmApp: Realm.App = new Realm.App({ id: APP_ID })

const getValidAccessToken = async () => {
  const app = realmApp
  if (app.currentUser) {
    await app.currentUser.refreshCustomData()
  }

  return app.currentUser?.accessToken
}

const graphqlUri = `https://eu-central-1.aws.realm.mongodb.com/api/client/v2.0/app/${APP_ID}/graphql`
const client = new ApolloClient({
  link: new HttpLink({
    uri: graphqlUri,
    fetch: async (uri, options = {}) => {
      const accessToken = await getValidAccessToken()
      return fetch(uri, {
        ...options,
        headers: {
          ...options.headers,
          Authorization: `Bearer ${accessToken}`,
        },
      })
    },
  }),
  cache: new InMemoryCache(),
})

const App = () => (
  <Suspense fallback={<Loader />}>
    <ApolloProvider client={client}>
      <AuthProvider realmClient={realmApp}>
        <Toaster />
        <ChakraProvider theme={theme}>
          <Routes />
        </ChakraProvider>
      </AuthProvider>
    </ApolloProvider>
  </Suspense>
)

export default App
