import { Box, Flex, Heading } from '@chakra-ui/react'

type Props = {
  title?: string
  variant?: ['invert', 'outward']
}

const Card: React.FC<Props> = ({ children, title, variant = 'outward' }) => (
  <Box
    bg={'white'}
    style={
      variant === 'outward'
        ? {
            boxShadow: '0 -2px 0 hsl(0, 0%, 100%)',
          }
        : {
            boxShadow:
              'inset 0 2px 0 hsl(119, 10%, 63%), 0 1px 1px hsl(0, 1%, 1% 1%)',
          }
    }
    rounded="lg"
    overflow="hidden"
    p={[0, 4]}
    mt={4}
  >
    <Flex justifyContent="space-between" alignItems="center">
      {title && <Heading fontSize="xl">{title}</Heading>}
    </Flex>
    <Flex flexDir="column">{children}</Flex>
  </Box>
)

export default Card
