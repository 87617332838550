import Lottie from 'react-lottie'
/* eslint-disable */
import * as animationData from '../../treeAnimation.json'

const Animation = () => (
  <Lottie
    options={{
      loop: false,
      autoplay: true,
      animationData: animationData,
      rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice',
      },
    }}
  />
)
/* eslint-enable */

export default Animation
