import { useEffect } from 'react'
import { Center, Flex } from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import Card from '../../components/Card'
import Layout from '../../components/Layout'
import { RegisterDTO } from '../../types/user'
import RegisterForm from './RegisterForm'
import { useAuth } from '../../hooks'

const Register: React.FC = () => {
  const { t } = useTranslation()
  const { user, register } = useAuth()
  const navigate = useNavigate()

  const handleSubmit = async (values: RegisterDTO) => {
    await register(values)
  }

  useEffect(() => {
    if (user) {
      navigate('/')
    }
  }, [user])

  return (
    <Layout
      display="flex"
      contentBg="bg"
      hideLogo
      minH={['80vh']}
      alignItems="center"
      background={['#fffff', 'bg']}
    >
      <Center>
        <Flex direction={'column'}>
          <Card title={t('logInPage.register')}>
            <RegisterForm onSubmit={handleSubmit} />
          </Card>
        </Flex>
      </Center>
    </Layout>
  )
}

export default Register
