import { useEffect, useState } from 'react'
import { Center } from '@chakra-ui/react'
import { gql, useMutation } from '@apollo/client'
import Layout from '../../components/Layout'
import Card from '../../components/Card'
import { useAuth } from '../../hooks'
import LinkForm from '../../components/Formik/Forms/linkForm'
import Loader from '../../components/Loader'
import { newLinkMutation } from './query'
import { useSnackBar } from '../../hooks/useSnackBar'

const linkMutation = gql`
  mutation insertOneLink($data: LinkInsertInput!) {
    insertOneLink(data: $data) {
      ${newLinkMutation}
    }
  }
`

const NewLink: React.FC = () => {
  const [createLink] = useMutation(linkMutation)
  const [loading, setLoading] = useState(true)
  const { user } = useAuth()
  const { sendToast } = useSnackBar()

  const onSubmit = async (values: any, actions: any) => {
    try {
      // const uniqueId = nanoid()
      await createLink({
        variables: {
          data: {
            ...values,
            userId: { link: user.id },
            newLink: `${values.newLink}`,
            // uniqueId,
          },
        },
      })
      actions.setSubmitting(false)
      actions.resetForm()
      sendToast('Linkki luotu onnistuneesti')
    } catch (error) {
      actions.setSubmitting(false)
      actions.resetForm()
    }
  }

  useEffect(() => {
    setTimeout(() => setLoading(false), 2000)
  }, [])

  return (
    <Layout
      display="flex"
      contentBg="bg"
      backButton
      minH={['100vh']}
      alignItems="center"
      background={['#fffff', 'bg']}
    >
      <Center>
        <Card>
          {loading ? (
            <Loader spinner={false} />
          ) : (
            <LinkForm onSubmit={onSubmit} />
          )}
        </Card>
      </Center>
    </Layout>
  )
}

export default NewLink
