import { useEffect, useState } from 'react'
import { Form, Formik } from 'formik'
import { useTranslation } from 'react-i18next'
import * as yup from 'yup'
import { FormikInput, SubmitButton } from '../Inputs'
import { formikErrorLocalisations } from '../../../lib/i18n'

formikErrorLocalisations()

const validationSchema = yup.object().shape({
  originalLink: yup.string().url().required(),
  newLink: yup.string().required().min(2, 'atleastTwoCharacters'),
})

interface Props {
  onSubmit: (values: any, actions: any) => void
}

const schema = yup.object().shape({
  url: yup.string().url(),
})

const LinkForm: React.FC<Props> = ({ onSubmit }: any) => {
  const { t } = useTranslation()
  const initialValues = {
    originalLink: '',
  }
  const [values, setValues] = useState(initialValues)

  useEffect(() => {
    const copyToClipboard = async () => {
      const clipboardContent: string = await navigator.clipboard.readText()
      const validUrl = await schema.isValid({ url: clipboardContent })
      if (validUrl) {
        setValues({ originalLink: clipboardContent })
      }
    }
    copyToClipboard()
  }, [])

  return (
    <Formik
      validationSchema={validationSchema}
      onSubmit={onSubmit}
      initialValues={values}
    >
      <Form>
        <FormikInput
          type="text"
          placeholder={t('formLink.orignalLink')}
          name="originalLink"
        />
        <FormikInput
          type="text"
          placeholder={t('formLink.newLink')}
          name="newLink"
        />
        <SubmitButton> {t('formLink.createLink')} </SubmitButton>
      </Form>
    </Formik>
  )
}

export default LinkForm
