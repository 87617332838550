import { Form, Formik } from 'formik'
import { useTranslation } from 'react-i18next'
import * as yup from 'yup'
import { FormikInput, SubmitButton } from '../../components/Formik/Inputs'
import { ForgotPasswordProps } from '../../types/auth'
import { formikErrorLocalisations } from '../../lib/i18n'

formikErrorLocalisations()

const validationSchema = yup.object().shape({
  email: yup.string().nullable().required().min(4, 'tooShortMinFour'),
})

const EmailForm: React.FC<ForgotPasswordProps> = ({ onSubmit }) => {
  const { t } = useTranslation()

  const initialValues = {
    email: '',
  }

  return (
    <Formik
      onSubmit={onSubmit}
      initialValues={initialValues}
      validationSchema={validationSchema}
    >
      <Form>
        <FormikInput type="email" placeholder={t('logInPage.email')} name="email" />
        <SubmitButton> {t('logInPage.send')} </SubmitButton>
      </Form>
    </Formik>
  )
}

export default EmailForm
