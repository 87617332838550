import { gql } from '@apollo/client'

export const getOwnProfile = () => gql`
query($userId: ObjectId) {
  profile (query: { userId: $userId }) {
      ${memberQueryProperties}
   }
  }
`
export const memberQueryProperties = `
    _id
    userId
    firstName
    lastName
`
