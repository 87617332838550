import { lazy, Suspense } from 'react'
import { Route, BrowserRouter, Routes } from 'react-router-dom'
import Loader from './components/Loader'
import ProtectedRoute from './components/ProtectedRoute'
import { ForgotPassword, Register, LoginPage, NewLink } from './pages'

const LazyDashboard = lazy(() => import('./pages/Dashboard'))
const LazyRerouteSite = lazy(() => import('./pages/Reroute'))

const AppRoutes = () => (
  <BrowserRouter>
    <Suspense fallback={<Loader />}>
      <Routes>
        <Route
          path="/"
          element={
            <ProtectedRoute>
              <LazyDashboard />
            </ProtectedRoute>
          }
        />
        <Route
          path="/newLink"
          element={
            <ProtectedRoute>
              <NewLink />
            </ProtectedRoute>
          }
        />
        <Route path="/register" element={<Register />} />
        <Route path="/login" element={<LoginPage />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/:id" element={<LazyRerouteSite />} />
      </Routes>
    </Suspense>
  </BrowserRouter>
)

export default AppRoutes
