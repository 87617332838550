export const primaryColor = '#83C082'

const styles = {
  global: {
    fontFamily: 'Arial',
    body: {
      margin: 0,
    },
  },
  space: {
    default: '2em',
  },
  colors: {
    gray: 'rgba(32, 50, 98, 0.1)',
    bg: '#83C082',
    primary: primaryColor,
    secondary: '#83C082',
    primaryScheme: {
      500: primaryColor,
    },
  },
}

export default styles
