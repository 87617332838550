import { useLocation, Navigate } from 'react-router'
import { useAuth } from '../hooks'
import Loader from './Loader'

const ProtectedRoute: React.FC<{ children: any }> = ({ children }) => {
  const { isAuthenticated, profile, authLoading } = useAuth()

  const location = useLocation()

  if (!isAuthenticated) {
    return <Navigate to="/login" state={{ from: location }} replace />
  }

  if (authLoading) {
    return <Loader />
  }

  if (!profile) {
    return <Navigate to="/profile/edit" />
  }

  return children
}

export default ProtectedRoute
