import { Form, Formik } from 'formik'
import { Flex } from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'
import createValidator from 'class-validator-formik'
import * as yup from 'yup'
import { FormikInput, SubmitButton } from '../../components/Formik/Inputs'
import { FormikPasswordInput } from '../../components/Formik/Inputs/PasswordInput'
import RouterLink from '../../components/Basic/RouterLink'
import { RegisterRequest } from '../../validation/auth'
import { AuthRegisterProps } from '../../types/auth'
import { formikErrorLocalisations } from '../../lib/i18n'

formikErrorLocalisations()

const validationSchema = yup.object().shape({
  email: yup.string().required(),
  password: yup.string().required(),
})

const RegisterForm: React.FC<AuthRegisterProps> = ({ onSubmit }) => {
  const { t } = useTranslation()

  const initialValues = {
    email: '',
    password: '',
    repeatedPassword: '',
  }

  return (
    <Formik
      onSubmit={onSubmit}
      initialValues={initialValues}
      validate={createValidator(RegisterRequest)}
    >
      <Form>
        <FormikInput
          type="email"
          placeholder={t('logInPage.email')}
          name="email"
        />
        <FormikPasswordInput
          placeholder={t('logInPage.password')}
          name="password"
        />
        <FormikPasswordInput
          placeholder={t('logInPage.secondPassword')}
          name="repeatedPassword"
        />
        <Flex flexDir="row" justifyContent="flex-end" mt={5} mb={5}>
          <RouterLink to="/">{t('logInPage.alreadyHaveAccount')}</RouterLink>
          <br />
        </Flex>

        <SubmitButton> {t('logInPage.register')} </SubmitButton>
      </Form>
    </Formik>
  )
}

export default RegisterForm
