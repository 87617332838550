import { Flex } from '@chakra-ui/react'
import { Spinner } from '@chakra-ui/spinner'
import IconAnimation from './Basic/IconAnimation'

interface Props {
  spinner?: boolean
}

const Loader: React.FC<Props> = ({ spinner = true }: Props) => (
  <Flex mt={5} justifyContent="center">
    {spinner ? <Spinner size="xl" /> : <IconAnimation />}
  </Flex>
)

export default Loader
