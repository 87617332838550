// @ts-ignore
import { Flex, BoxProps, Box } from '@chakra-ui/layout'
import { Heading, Text } from '@chakra-ui/react'
import { defaultPadding } from '../theme'
import Loader from './Loader'
import BackButton from './Buttons/BackButton'
import TopBar from './TopBar'

interface Props extends BoxProps {
  loading?: boolean
  error?: Error
  title?: string
  backButton?: boolean
  hideLogo?: boolean
  headerBackground?: string
  contentBg?: string
}

const Layout: React.FC<Props> = ({
  children,
  error,
  title,
  contentBg,
  loading,
  hideLogo,
  backButton,
  headerBackground,
  ...props
}) => (
  <Box w="100%" minH="100vh" bg={['#fff', 'bg']}>
    <Box
      position="absolute"
      top={0}
      pointerEvents="none"
      left={0}
      right={0}
      h={headerBackground ? '42vh' : '80vh'}
    >
      <TopBar
        hideLogo={hideLogo}
        LeftComponent={backButton ? BackButton : null}
        bgBehind={!!headerBackground}
      />
    </Box>

    <Box pt={headerBackground ? [0, '50px'] : 0} />

    <Box
      h={headerBackground && [300, '42vh']}
      bgPosition="center"
      bgSize="cover"
      bgColor={!headerBackground ? '#fffff' : ''}
      bgImage={headerBackground}
    />
    <Flex {...props} maxW={'100vw'} justifyContent="center">
      {error && <Text> {error.message || error}</Text>}
      {loading && !error ? <Loader /> : null}

      {!loading && !error ? (
        <Box
          bg={contentBg || '#fffff'}
          marginTop={!headerBackground ? '64px' : 0}
          paddingBottom={14}
          px={defaultPadding}
          maxW="100%"
        >
          {title && <Heading mb={defaultPadding}>{title}</Heading>}
          {children}
        </Box>
      ) : null}
    </Flex>
  </Box>
)

export default Layout
