import { Center, Flex } from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'
import Card from '../../components/Card'
import Layout from '../../components/Layout'
import EmailForm from './EmailForm'
import { EmailDTO } from '../../types/user'

const ForgotPassword: React.FC = () => {
  const { t } = useTranslation()

  const handleSubmit = async (values: EmailDTO) => {}

  return (
    <Layout
      display="flex"
      contentBg="bg"
      minH={['80vh']}
      hideLogo
      alignItems="center"
      background={['#fffff', 'bg']}
    >
      <Center>
        <Flex direction={'column'}>
          <Card title={t('logInPage.forgotPassword')}>
            <EmailForm onSubmit={handleSubmit} />
          </Card>
        </Flex>
      </Center>
    </Layout>
  )
}

export default ForgotPassword
